
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { firebaseErrors } from "../common/methods/firebaseErrors";
import { getAuth, sendPasswordResetEmail } from "@firebase/auth";
// import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    // const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      const auth = getAuth();
      sendPasswordResetEmail(auth, values.email)
        .then(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: "Password reset email sent! Check your email.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(() => {
            router.push({ name: "dashboard" });
          });
        })
        .catch((error) => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          firebaseErrors(error.code);
        });
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
